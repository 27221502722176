import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getOrderDetails, deliverOrder } from '../../actions/orderActions'
import { ORDER_DELEVERED_RESET } from '../../constants/orderConstants'
import SubNav from '../../components/SubNav'
import { Helmet } from 'react-helmet'

const TermsConditionScreen = ({ match, history }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container>
      <Helmet>
        <meta charset='UTF-8' />
        <title>Terms & Conditions | 99bookscart</title>
        <meta name='title' content='Terms & Conditions | 99bookscart'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on 99bookscart'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      <Container>
        {!userInfo && <SubNav history={history}></SubNav>}
        <Row>
          <Container class='py-3'>
            <h2>Terms & Conditions</h2>
            <p>
              These terms and conditions ("Terms") govern your use of 99bookscart
              service ("Service") provided by 99bookscart ("we," "us," or "our").
              By accessing or using the Service, you agree to be bound by these
              Terms.
            </p>
            <h4>Description of Service</h4>
            <p>
              The Service provides subscribers with access to book key Ideas of
              selected titles. The Ideas are created by our team of writers and
              are intended to provide a concise overview of the main ideas
              presented in the book.
            </p>
            <h4>Subscription and Payment</h4>
            <p>
              To access the Service, you must subscribe and pay the monthly
              subscription fee of ₹99. The fee will be charged automatically to
              your designated payment method on a recurring basis until you
              cancel your subscription.
            </p>
            <h4>Cancellation and Refunds</h4>
            <p>
              You may cancel your subscription at any time by logging into your
              account and following the cancellation instructions. We do not
              provide refunds for any unused portion of your subscription.
            </p>
            <h4>Intellectual Property</h4>
            <p>
              The book Ideas provided through the Service are protected by
              copyright law and are the property of 99bookscart or its licensors.
              You may not copy, distribute, or otherwise use the summaries for
              any purpose other than personal use.
            </p>
            <h4>Disclaimer of Warranties</h4>
            <p>
              The Service is provided on an "as is" and "as available" basis. We
              do not make any representations or warranties of any kind, express
              or implied, regarding the Service or the accuracy, completeness,
              or reliability of the Book Ideas.
            </p>
            <h4>Limitation of Liability</h4>
            <p>
              In no event shall 99bookscart or its affiliates, directors,
              officers, employees, or agents be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              connection with the use of the Service or the summaries.
            </p>
            <h4>Indemnification</h4>
            <p>
              You agree to indemnify and hold harmless 99bookscart and its
              affiliates, directors, officers, employees, and agents from any
              claim or demand, including reasonable attorneys' fees, made by any
              third party due to or arising out of your use of the Service or
              your violation of these Terms.
            </p>
            <h4>Changes to Terms and Service</h4>
            <p>
              We reserve the right to modify these Terms at any time. If we make
              changes, we will post the updated Terms on our website. Your
              continued use of the Service after any such changes indicates your
              acceptance of the updated Terms.
            </p>
            <p>
              We also reserve the right to modify or discontinue the Service at
              any time, without notice or liability.
            </p>
            <h4>Governing Law and Dispute Resolution</h4>
            <p>
              These Terms shall be governed by and construed in accordance with
              the laws of India. Any dispute arising out of or in connection
              with these Terms shall be resolved through binding arbitration in
              accordance with the rules.
            </p>
            <h4>Entire Agreement</h4>
            <p>
              These Terms constitute the entire agreement between you and
              99bookscart with respect to the Service and supersede all prior or
              contemporaneous communications and proposals, whether oral or
              written, between you and 99bookscart.
            </p>
            <p>
              If any provision of these Terms is found to be invalid or
              unenforceable, the remaining provisions shall remain in full force
              and effect.
            </p>
            <p>
              By subscribing to the Service, you acknowledge that you have read,
              understood, and agree to be bound by these Terms.
            </p>
          </Container>
        </Row>
      </Container>
    </Container>
  )
}

export default TermsConditionScreen
