import React, { useState, useEffect, useRef } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { register } from '../../../actions/userActions'
import { getCategory, convertToSlug } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import Story from '../../../components/Story'
import StoryStats from '../../../components/StoryStats'
import { FaBookReader, FaLock, FaClock, FaPaperclip } from 'react-icons/fa'
import SocialShare from '../../../components/SocialShare'
import SubNav from '../../../components/SubNav'
import SocialLogin from '../../../components/SocialLogin'
import BookMeta from '../../../components/litplots/meta/BookMeta'
import Rating from '../../../components/Rating'
import LoginForm from '../../../components/litplots/LoginForm'
import AudioPlayer from '../../../components/litplots/player/AudioPlayer'
import { Helmet } from 'react-helmet'
import StepFunnel from '../../../components/litplots/StepFunnel'
import { useLocation } from 'react-router-dom'

const PlotViewScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  const url = `/app/en/book/${match.params.title}/${match.params.id}`

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isReadMore, setIsReadMore] = useState(true)
  const getStoryRed = useSelector((state) => state.getStory)
  const {
    story,
    StoryStats: stats,
    similarStories,
    loading,
    error,
    success,
  } = getStoryRed

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  var currency = searchParams.get('currency') || 'INR'
  if (currency != 'INR') {
    localStorage.setItem('currency', 'USD')
  } else {
    localStorage.setItem('currency', 'INR')
  }
  const handleClose = () => {
    setShow(false)
  }

  const toggleReadMore = () => {
    if (userLoggedIn) {
      setIsReadMore(!isReadMore)
    } else {
      history.push('/Login')
    }
  }
  const subscribeButton = () => {
    if (userLoggedIn) {
      history.push(`/app/en/plot/${story.episodes[0]._id}`)
    } else {
      //history.push(`/app/en/plot/${story.episodes[0]._id}`)
      setShow(true)
    }
  }

  useEffect(() => {
    // if (!userInfo) {
    //   history.push(`/register?redirect=${url}`)
    // }
    window.scrollTo(0, 0)
    dispatch(getStory(match.params.title))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app')
      }
    }
  }, [dispatch, match, userInfo, registerSuccess, emailError])
  return (
    <Container>
      {story && <BookMeta story={story}></BookMeta>}
      <SubNav history={history}></SubNav>
      {loading || !success ? (
        <Loader></Loader>
      ) : (
        <>
          <Helmet>
            <title>
              {story.title} by {story.user && story.user.name} | 99bookscart
            </title>
            <meta charset='UTF-8' />
            <meta
              name='title'
              content={`${story.title} by ${
                story.user && story.user.name
              } | 99bookscart`}
            ></meta>
            <meta
              name='description'
              content={`${story.description.slice(0, 150)}`}
            ></meta>
            <meta
              name='keyword'
              content={`${story.title} by ${
                story.user && story.user.name
              } | 99bookscart`}
            ></meta>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1.0'
            ></meta>
            <meta name='robots' content='index, follow' />
          </Helmet>
          <Container>
            <Row className='story-head-section'>
              <Col xs={5}>
                <div>
                  <Image
                    src={story.coverImage}
                    layout='responsive'
                    height='230'
                    width='150'
                    className='storypagecover'
                  />
                </div>
              </Col>
              <Col xs={7}>
                {story.audio && (
                  <AudioPlayer
                    src={story.audio}
                    name={story.title}
                    history={history}
                  />
                )}
                <div style={{ padding: '8px' }}>
                  <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
                    {story.title}
                  </div>
                  <div className='writer-name'>
                    {/* <Link to={`/user/${story.user._id}`}> */}
                    <span className='text-muted'>
                      {story.user && story.user.name}
                    </span>
                    {/* </Link> */}
                  </div>
                  <Rating value={story.rating}></Rating>{' '}
                  <small className='reviews-count'>
                    {story.reviewCount.toLocaleString()} Reviews
                  </small>
                  <div className='desktop-view' style={{ width: '50%' }}>
                    <StoryStats story={story}></StoryStats>
                  </div>
                  <div>
                    <Button
                      className='btn-primary'
                      style={{ marginTop: '15px' }}
                      onClick={subscribeButton}
                    >
                      <FaPaperclip />
                      &nbsp; Read
                    </Button>
                  </div>
                  {/* <div style={{ float: 'left', marginTop: '10px' }}>
                    <div className='storiestatslabel'>
                      <FaClock />{' '}
                      <span className='storiestatsvalue'>
                        {story.totalReadingTime}
                      </span>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: '15px' }}>
            <Col>
              <>
                <div className='mobile-view'>
                  <StoryStats story={story}></StoryStats>
                </div>
                <h3 className='storietaglabel mb-1'>What's in the book? </h3>
                <div className='storydescription'>
                  {isReadMore
                    ? ReactHtmlParser(
                        story.description && story.description.slice(0, 700)
                      )
                    : ReactHtmlParser(story.description)}
                  <div onClick={toggleReadMore} className='read-or-hide'>
                    {isReadMore ? '...read more' : ' show less'}
                  </div>
                </div>
                {/* <h2>Key Ideas</h2>
                <div className='storychaptersection'>
                  {story.episodes.map((episode, index) => (
                    <div key={episode._id}>
                      {!story.isPaid ? (
                        <Link to={`/app/en/plot/${episode._id}`}>
                          <Row key={episode._id} className='chaptersection'>
                            <Col xs={12} className='chapter-title'>
                              {'#' + (index + 1) + ' ' + episode.title}
                              <br />
                            </Col>
                          </Row>
                        </Link>
                      ) : (
                        <Link to={`/app/en/plot/${episode._id}`}>
                          <Row key={episode._id} className='chaptersection'>
                            <Col xs={12} className='chapter-title'>
                              {'#' + (index + 1) + ' ' + episode.title}
                              <br />
                            </Col>
                          </Row>
                        </Link>
                      )}
                    </div>
                  ))}
                </div> */}

                <div>
                  {typeof story.category === 'string' ? (
                    <>{getCategory(story.category)}</>
                  ) : (
                    <>
                      <h2>Related Topics</h2>
                      {story.category.map((cat) => (
                        <Link to={`/en/category/${cat.name}`} key={cat._id}>
                          <span key={cat._id} className='topic'>
                            <span key={cat._id}>{cat.name}</span>
                          </span>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
                {story.tags.length > 0 && (
                  <div className='story-tags'>
                    <span className='storietaglabel'>Tags: </span>
                    {story.tags.map((tag) => (
                      <Link
                        to={`/stories`}
                        key={`${tag._id}`}
                        className='topic'
                      >
                        <span>{tag.name}</span>
                      </Link>
                    ))}
                  </div>
                )}

                <SocialShare story={story}></SocialShare>
              </>
            </Col>
          </Row>
          <Container>
            <h1>Related Books</h1>
            <Row>
              {similarStories.map((story) => (
                <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                  <Route
                    render={({ history }) => (
                      <Story history={history} story={story} key={story._id} />
                    )}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
      <Modal show={show} onHide={handleClose}>
        <StepFunnel history={history}></StepFunnel>
      </Modal>
    </Container>
  )
}

export default PlotViewScreen
