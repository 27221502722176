import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../actions/userActions'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
} from 'react-bootstrap'
import SocialLogin from '../SocialLogin'
import { LinkContainer } from 'react-router-bootstrap'
import { TextField } from '@mui/material'
function LoginForm({ history }) {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [emailError, setEmailError] = useState('')
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  useEffect(() => {
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  const handleClose = () => {
    setShow(false)
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    setPasswordError('')
    if (password.length < 5) {
      setPasswordError('Password is too short')
    } else {
      dispatch(
        register({ name, email, password, isSocial: false, shorts: true })
      )
    }
  }
  return (
    <Modal.Body>
      <Form onSubmit={submitHandler2}>
        {/* <div>
          <Button className='close-button' onClick={handleClose}>
            X
          </Button>
        </div> */}
        <div style={{ padding: '20px' }}>
          <Form.Group>
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
              <Link to={'/'}>
                <img
                  src='../../images/logo.png'
                  className='siteLogo'
                  alt='99bookscart logo'
                />
              </Link>
              <br />
              <strong className='subtagline'>Learn More, Read Less</strong>
              <br />
              <small>1,000+ Non Fiction Books</small>
              <div>Be the Smartest Person in the Room</div>
              <br />
              <div className='social-logins'>
                <SocialLogin />
              </div>
            </div>
            <Form.Text className='red-text'>
              {errorMessage && <span>{errorMessage}</span>}
            </Form.Text>
            {/* <TextField
              id='outlined-basic'
              label='Full Name'
              variant='outlined'
              required
              type='Text'
              autoComplete='off'
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            /> */}
            <Form.Text className='red-text'>
              {emailError && <span>{emailError}</span>}
            </Form.Text>
            <TextField
              id='outlined-basic'
              label='Email Address'
              variant='outlined'
              required
              type='Email'
              autoComplete='off'
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
            <Form.Text className='red-text'>
              {passwordError && <span>{passwordError}</span>}
            </Form.Text>
            <TextField
              id='outlined-basic'
              label='Password'
              variant='outlined'
              required
              type='Password'
              autoComplete='off'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </Form.Group>
          <Row>
            <Col>
              <a href={'/'}>Back to Home</a>
            </Col>
            <Col>
              <Button type='submit' variant='primary' className='float-right'>
                Sign Up
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className='text-center mt-2'>
              Existing Customer? <Link to={'/login'}>Login</Link>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal.Body>
  )
}

export default LoginForm
