import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Container,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { login, forgotPassword } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import SubNav from '../components/SubNav'
import SocialLogin from '../components/SocialLogin'
import { TextField } from '@mui/material'
import { Helmet } from 'react-helmet'
import StepFunnel from '../components/litplots/StepFunnel'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [remail, setRemail] = useState('')
  const [showResetPassMessage, setShowResetPassMessage] = useState('')
  const [showMainForm, setShowMainForm] = useState(true)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const forgotPasswordRed = useSelector((state) => state.forgotPassword)
  const {
    loading: fploading,
    error: fperror,
    result,
    success: fpsuccess,
  } = forgotPasswordRed

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userInfo) {
      history.push(redirect)
    }
    if (fpsuccess) {
      if (result.error && result.errorMessage) {
        setShowResetPassMessage(result.errorMessage)
      } else {
        setShowResetPassMessage('Password Reset Email has been Sent')
        setRemail('')
      }
    }
  }, [history, userInfo, redirect, fpsuccess])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  const forgotPasswordHandler = (e) => {
    e.preventDefault()
    setShow(true)
  }
  const handleClose = (e) => {
    setShow(false)
  }
  const forgotpasswordsubmitHandler = (e) => {
    e.preventDefault()
    dispatch(forgotPassword({ email: remail }))
  }
  return (
    <Container>
      <Helmet>
        <meta charset='UTF-8' />
        <title>Log in | 99bookscart</title>
        <meta name='title' content='Log in | 99bookscart'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on 99bookscart'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      {/* <SubNav history={history}></SubNav> */}

      <Row>
        <Col md={12}>
          {showMainForm && (
            <div style={{ padding: '20px' }} className='login-box'>
              <FormContainer>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <Link to={'/'}>
                    {' '}
                    <img
                      src='../../images/logo.png'
                      className='siteLogo'
                      alt='litplots logo'
                    />
                  </Link>
                  <br />
                  <strong className='subtagline'>Welcome Back!</strong>
                  <br />
                  <div className='social-logins'>
                    <SocialLogin />
                  </div>
                </div>
                {error && <div className='red-text'>{error}</div>}
                {/* {loading && <Loader></Loader>} */}
                <Form onSubmit={submitHandler}>
                  <Form.Group controlId='email'>
                    <TextField
                      id='outlined-basic'
                      label='Email Address'
                      variant='outlined'
                      required
                      type='Email'
                      autoComplete='off'
                      value={email || ''}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                  </Form.Group>
                  <Form.Group controlId='password'>
                    <TextField
                      id='outlined-basic'
                      label='Password'
                      variant='outlined'
                      required
                      type='Password'
                      autoComplete='off'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: '100%', marginBottom: '10px' }}
                    />
                  </Form.Group>
                  <div>
                    <Button
                      type='submit'
                      varaint='primary'
                      style={{
                        float: 'right',
                        width: '100%',
                        marginBottom: '20px',
                      }}
                    >
                      Login
                    </Button>
                    <div>
                      <br />
                    </div>
                  </div>
                  <div style={{ marginTop: '25px' }}>
                    <Link to={'/register'} style={{ float: 'left' }}>
                      {' '}
                      <small>New Constomer?</small>
                    </Link>
                    <Link
                      to={''}
                      style={{ float: 'right' }}
                      onClick={(e) => forgotPasswordHandler(e)}
                    >
                      <small>Forgot Password </small>
                    </Link>
                    <br />
                  </div>
                </Form>
                <br />
              </FormContainer>
            </div>
          )}
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Row>
            <Col>
              <Button
                variant='secondary'
                onClick={handleClose}
                style={{
                  float: 'right',
                  margin: '10px',
                  backgroundColor: '#022335',
                  color: '#fff',
                }}
              >
                X
              </Button>
            </Col>
          </Row>

          <Form
            onSubmit={forgotpasswordsubmitHandler}
            className='cart-form-password'
          >
            <Form.Group>
              <h5>Forgot Password?</h5>
              <p>Enter email to reset password</p>
              {showResetPassMessage && (
                <span className='red-text'>{showResetPassMessage}</span>
              )}
              <TextField
                id='outlined-basic'
                label='Email Address'
                variant='outlined'
                required
                type='Email'
                autoComplete='off'
                value={remail || ''}
                onChange={(e) => setRemail(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />
            </Form.Group>
            <Button
              type='submit'
              variant='primary'
              className='float-right'
              style={{ marginBottom: '20px' }}
            >
              Reset Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default LoginScreen
