import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getOrderDetails, deliverOrder } from '../../actions/orderActions'
import { ORDER_DELEVERED_RESET } from '../../constants/orderConstants'
import SubNav from '../../components/SubNav'
import { Helmet } from 'react-helmet'

const ReturnRefundScreen = ({ match, history }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <meta charset='UTF-8' />
        <title>Return & Refund Policy | 99bookscart</title>
        <meta name='title' content='Return & Refund Policy | 99bookscart'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on 99bookscart'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      <Container>
        {!userInfo && <SubNav history={history}></SubNav>}
        <Row>
          <div class='py-3'>
            <h2>Return & Refund Policy</h2>

            <p>
              If you are not entirely satisfied with your purchase, we’re here
              to help.
            </p>
            <p>
              <strong>Returns &amp; Replacement</strong>
            </p>
            <p>
              You have<span>&nbsp;</span>11<span>&nbsp;</span>calendar days to
              return an item from the date you received it.&nbsp;
              <span>
                To be eligible for a return, your item must be unused and in the
                same condition that you received it.&nbsp;
              </span>
              <span>Your item must be in the original packaging.&nbsp;</span>
              <span>
                Your item needs to have the receipt or proof of purchase.
              </span>
            </p>
            <p>
              <strong>Refunds</strong>
            </p>
            <p>
              Once we receive your item, we will inspect it and notify you that
              we have received your returned&nbsp;
              <span>
                item. We will immediately notify you on the status of your
                refund after inspecting the item.&nbsp;
              </span>
              <span>
                If your return is approved, we will initiate a refund to your
                credit card (or original method of payment).&nbsp;
              </span>
              <span>
                You will receive the credit within a certain amount of days,
                depending on your card issuer’s policies. Or send you the
                replacement order.
              </span>
            </p>
            <p>
              <strong>Shipping</strong>
            </p>
            <p>
              You will be responsible for paying for your own shipping costs for
              returning your item. Shipping costs are non refundable.&nbsp;
              <span>
                If you receive a refund, the cost of return shipping will be
                deducted from your refund.
              </span>
            </p>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              If you have any questions on how to return your item to us,
              contact us.&nbsp;<span>Email id here: info@99bookscart.com</span>
            </p>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ReturnRefundScreen
