import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
} from 'react-bootstrap'
import SubNav from '../../components/SubNav'
import { Helmet } from 'react-helmet'

const PrivacyPolicyScreen = ({ match, history }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container>
      <Helmet>
        <meta charset='UTF-8' />
        <title>Privacy Policy | 99bookscart</title>
        <meta name='title' content='Privacy Policy | 99bookscart'></meta>
        <meta
          name='description'
          content='Discover concise summaries of Non Fiction Books on 99bookscart'
        ></meta>
        <meta
          name='keyword'
          content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
        ></meta>
      </Helmet>
      <Container>
        {!userInfo && <SubNav history={history}></SubNav>}
        <Row>
          <Container class='py-3'>
            <h1>Privacy Policy</h1>
            <p>
              At 99bookscart, we take your privacy very seriously. This Privacy
              Policy explains how we collect, use, and protect your personal
              information when you use our online book ideas/summary service
              (the "Service"). By using the Service, you agree to the terms of
              this Privacy Policy.
            </p>
            <h4>Information We Collect</h4>
            <p>
              We collect information about you when you sign up for the Service,
              including your name and email address. We also collect information
              about your use of the Service, such as the books you search for
              and the plots you view.
            </p>

            <h4>How We Use Your Information</h4>
            <p>
              We use your information to provide the Service to you, including
              creating and delivering book plots, as well as sending you updates
              and promotional materials. We may also use your information to
              improve the Service, such as analyzing user behavior and
              preferences.
            </p>

            <h4>Information Sharing</h4>
            <p>
              We do not share your personal information with third parties,
              except as necessary to provide the Service to you or as required
              by law. We may share aggregate, non-personally identifiable
              information with third parties for marketing or research purposes.
            </p>

            <h4>Data Security</h4>
            <p>
              We take appropriate measures to protect your personal information
              from unauthorized access, disclosure, alteration, or destruction.
              We use industry-standard encryption technologies to protect
              sensitive information such as passwords and payment details.
            </p>

            <h4>Cookies</h4>
            <p>
              We use cookies to personalize and improve your experience with the
              Service. You can choose to disable cookies through your browser
              settings, but this may limit the functionality of the Service.
            </p>

            <h4>Changes to Privacy Policy</h4>
            <p>
              We reserve the right to modify this Privacy Policy at any time. If
              we make changes, we will post the updated Privacy Policy on our
              website. Your continued use of the Service after any such changes
              indicates your acceptance of the updated Privacy Policy.
            </p>

            <h4>Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at info@99bookscart.com.
            </p>
          </Container>
        </Row>
      </Container>
    </Container>
  )
}

export default PrivacyPolicyScreen
