import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { listStory, getStory } from '../../../actions/storyActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Form,
  Button,
  Table,
  Row,
  Col,
  Image,
  Modal,
  Nav,
  Navbar,
} from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'

import { getCategory } from '../../../helpers/helpers'
import Loader from '../../../components/Loader'
import { FaBookReader, FaHeadphones } from 'react-icons/fa'
import Message from '../../../components/Message'
import PaginateStory from '../../../components/PaginateStory'
import { register } from '../../../actions/userActions'
import Categories from '../../../components/Categories'
import Rating from '../../../components/Rating'

import { listStoryByCat } from '../../../actions/storyActions'
import Story from '../../../components/Story'
import HomeMeta from '../../../components/litplots/meta/HomeMeta'
import LoginForm from '../../../components/litplots/LoginForm'
import Calculator from '../../../components/litplots/player/Calculator'
import { listTopProducts } from '../../../actions/productActions'
import StepFunnel from '../../../components/litplots/StepFunnel'
import Notification from '../../../components/litplots/Notification'
import HomePageKiller from '../../../components/litplots/HomePageKiller'
import { useLocation } from 'react-router-dom'
import Collection from '../../../components/litplots/Collection'

const HomeScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1
  const dispatch = useDispatch()
  const location = useLocation()

  const [show, setShow] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disable, setDisable] = React.useState(false)
  const storybyCat = useSelector((state) => state.storybyCat)
  const {
    stories,
    loading: storyLoading,
    error: storyError,
    suceess: storySuccess,
    pages,
    page,
  } = storybyCat
  const productTopRated = useSelector((state) => state.productTopRated)
  const {
    loading: loadingTR,
    error: errorTR,
    products: freeBooks,
  } = productTopRated
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userLoggedIn } = userLogin
  const userRegister = useSelector((state) => state.userRegister)
  const {
    loading: loadingReg,
    error: errorReg,
    success: registerSuccess,
    userInfo,
  } = userRegister

  const searchParams = new URLSearchParams(location.search)
  var currency = searchParams.get('currency') || 'INR'
  if (currency != 'INR') {
    localStorage.setItem('currency', 'USD')
  } else {
    localStorage.setItem('currency', 'INR')
  }

  const handleClose = () => {
    setShow(false)
  }

  const startSub = () => {
    if (userLoggedIn) {
      history.push('/app/profile/billing')
    } else {
      setShow(true)
      //history.push('/register')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (userLoggedIn) {
      history.push('/app/profile/billing')
    }
    // dispatch(listTopProducts())
    dispatch(listStoryByCat('Money', pageNumber))
    if (registerSuccess && userInfo) {
      if (userInfo.errorMessage) {
        setEmailError(userInfo.errorMessage)
      } else {
        history.push('/app/profile/billing')
      }
    }
  }, [dispatch, userInfo, registerSuccess, emailError])
  return (
    <>
      <>
        <HomeMeta></HomeMeta>
        <>
          <Row>
            <Col xs={6}>
              <Link to={'/'}>
                <img
                  src='../../images/logo.png'
                  className='siteLogo-new'
                  alt='litplots logo'
                />
              </Link>
            </Col>

            <Col xs={6}>
              <Button onClick={startSub} className='subbutton-signup'>
                Sign up
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <div
                onClick={startSub}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '75%',
                  marginTop: '18px',
                }}
              >
                <Image
                  src='../../images/final.gif'
                  layout='responsive'
                  className='hero-image2'
                  alt={'book summary'}
                  width={'100%'}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div className='text-center' style={{ marginTop: '10px' }}>
              <Button onClick={startSub} className='subbuttonnx'>
                Start your free trail
              </Button>
            </div>
          </Row>
          <Collection />

          <HomePageKiller />
          <Row>
            <div className='text-center'>
              <Button onClick={startSub} className='subbuttonnx'>
                Start your free trail
              </Button>
            </div>
          </Row>
          {/* <Row className='story-hero-text'>
            <Col xs={12} sm={12}>
              <p
                style={{
                  color: '#333',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  padding: '20px',
                }}
              >
                For lifelong learners, busy professionals, or someone just
                curious about the world
                <br />
              </p>
              <div style={{ fontWeight: 'bold' }}>
                <strong
                  style={{
                    fontSize: '18px',
                    color: '#E74C3C',
                    fontWeight: 'bold',
                  }}
                >
                  Finish Books in 15 min
                </strong>
              </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col className='text-center'>
              <a href='/book-summary/atomic-habits/64141ac4a28f3b6b33f2fed7'>
                <h2>Atomic Habit book summaries</h2>
              </a>
              <a href='/category/nature-&-environment'>
                <h2>Self help book summaries</h2>
              </a>
              <a href='/book-summary/rich-dad-poor-dad/641450fba28f3b6b33f33312'>
                <h2>Rich Dad Poor Dad book summaries</h2>
              </a>
            </Col>
          </Row> */}

          <Row>
            <div style={{ textAlign: 'center' }}>
              <Image
                src='../../images/people.webp'
                layout='responsive'
                alt={'book summary'}
              />
              <br />
              <Rating value={5}></Rating>

              <span>3,498 Reviews</span>
              <br />
            </div>
            <Container>
              <Row className='text-center'>
                <div
                  style={{
                    textAlign: 'center',
                    color: '#333',
                    fontSize: '15px',
                    marginTop: '12px',
                  }}
                >
                  Employees from Top 500 companies read NexNuggets
                </div>
                <Col xs={6} sm={3}>
                  <img
                    src='../images/yahoo.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                  <br />
                  <img
                    src='../images/reuters.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                </Col>
                <Col xs={6} sm={3}>
                  <img
                    src='../images/enterpreneur.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                  <br />
                  <img
                    src='../images/tc.svg'
                    alt='clear logic'
                    style={{ width: '70px' }}
                  />
                </Col>
                <Col xs={6} sm={3}>
                  <img
                    src='../images/euronews.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                  <br />
                  <img
                    src='../images/muo.svg'
                    alt='clear logic'
                    style={{ width: '90px' }}
                  />
                </Col>
                <Col xs={6} sm={3}>
                  <img
                    src='../images/frame.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                  <br />
                  <img
                    src='../images/daily-mail.svg'
                    alt='clear logic'
                    style={{ width: '150px' }}
                  />
                </Col>
              </Row>
            </Container>
            <Row>
              <Categories></Categories>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <h2
                style={{
                  color: '#022335',
                  padding: '18px',
                  fontSize: '24px',
                }}
              >
                Understand Books in 15 Minutes
              </h2>
            </div>
          </Row>
          <Row className='story-bg-points'>
            <Col xs={12} sm={4}>
              <div>
                <div>
                  <img
                    src='../images/1.png'
                    alt='clear logic'
                    className='ben-img'
                  />
                </div>
                <div>
                  <h3 className='pb-1 themecolor' style={{ fontSize: '20px' }}>
                    Clear Logic
                  </h3>

                  <p style={{ fontSize: '16px' }}>
                    Unlock the power of knowledge with ease and clarity, as we
                    strive to simplify even the most complex of ideas!
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div>
                <div>
                  <img
                    src='../images/2.png'
                    alt='brilliant insights'
                    className='ben-img'
                  />
                </div>
                <div>
                  <h3 className='pb-1 themecolor' style={{ fontSize: '20px' }}>
                    Brilliant Insights
                  </h3>
                  <p>
                    Experience the thrill of intellectual discovery as we delve
                    deeper into the realm of literature!
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div>
                <div>
                  <img
                    src='../images/3.png'
                    alt='always precise'
                    className='ben-img'
                  />
                </div>
                <div>
                  <h3 className='pb-1 themecolor' style={{ fontSize: '20px' }}>
                    Always Precise
                  </h3>
                  <p>
                    Your time is precious, and we honor that by crafting every
                    single sentence with intention and purpose.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Container>
              <div className='home-sec33'>
                <h4
                  className='hero-text-33'
                  style={{ lineHeight: '33px', padding: '10px' }}
                >
                  A small investment, an incredible growth opportunity
                </h4>{' '}
                <h3>Be the best version of yourself!</h3>
              </div>
            </Container>
          </Row>
          {/* <Row>
            <Calculator />
          </Row> */}
          {/* <Container>
            <Row>
              <h2 className='text-center' style={{ fontSize: '20px' }}>
                Free Books
              </h2>
              {loadingTR ? (
                <Loader></Loader>
              ) : (
                freeBooks &&
                freeBooks.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <Story history={history} story={story} />
                      )}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Container> */}

          <>
            <Row
              style={{
                background: '#022335',
                color: '#fff',
                marginTop: '12px',
              }}
            >
              <Col sm={6}>
                <div className='home-secff'>
                  Enhance your knowledge
                  <br />
                  Achieve greater success
                  <br />
                  Improve your health
                  <br />
                  Become a better parent
                  <br />
                  Increase your happiness
                </div>
              </Col>
              <Col sm={6} className='home-secgg'>
                <div>
                  Engaging in just 30 minutes of reading can lower blood
                  pressure, heart rate, and feelings of psychological distress
                  as effectively as yoga and humor. According to a 2009 study by
                  the University of Sussex, reading can reduce stress by up to
                  <span style={{ color: '#F1C40F', fontWeight: 'bold' }}>
                    {' '}
                    68%
                  </span>
                  .
                </div>
                <br />
                <span style={{ color: '#F1C40F', fontWeight: 'bold' }}>
                  91%
                </span>{' '}
                of our users develop improved habits*
                <br />
                <span style={{ color: '#F1C40F', fontWeight: 'bold' }}>
                  87%
                </span>{' '}
                of our users have made beneficial changes in their lives*
                <br />
              </Col>
            </Row>
          </>
          <Row>
            <div className='home-sec33'>
              <Button onClick={startSub} className='subbuttonnx'>
                Start Reading Now
              </Button>
            </div>
          </Row>
          <Row style={{ backgroundColor: '#F1F7F3', padding: '10px' }}>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={4.9}></Rating>
                <span className='review-name'>James Redd</span>
                <br />
                <p className='review-text'>
                  Fastest way to complete any book and understand it
                </p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={5}></Rating>
                <span className='review-name'>Ryan</span>
                <br />
                <p className='review-text'>
                  It is very addicitive and I am loving it!Now I am a avid
                  reader.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='lp-rating-sect'>
                <Rating value={5}></Rating>
                <span className='review-name'>Neha</span>
                <br />
                <p className='review-text'>I absolutely love this service!</p>
              </div>
            </Col>
          </Row>
          <Notification></Notification>
          {/* <Row>
            <Col>
              <div className='hero-text2'>
                Read key ideas from 1000+ non fiction books
                <br />
                <Button onClick={startSub}>Explore Ideas</Button>
              </div>
            </Col>
          </Row> */}
          <Container>
            <Row>
              <br />
              <h2 className='text-center'>Invest in yourself</h2>

              {storyLoading ? (
                <Loader></Loader>
              ) : (
                stories &&
                stories.map((story) => (
                  <Col key={story._id} sm={6} md={6} lg={4} xs={12}>
                    <Route
                      render={({ history }) => (
                        <Story history={history} story={story} />
                      )}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Container>
        </>
        <Modal show={show} onHide={handleClose} backdrop='static'>
          {/* <LoginForm history={history} /> */}
          <StepFunnel history={history}></StepFunnel>
        </Modal>
      </>
    </>
  )
}
{
}

export default HomeScreen
