import React from 'react'
import Helmet from 'react-helmet'
const HomeMeta = () => {
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'NexNuggets',
    url: 'https://plots.99bookscart.com/',
    logo: 'https://plots.99bookscart.com/images/logo.png',
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate:
          'https://plots.99bookscart.com/search/{search_term_string}/true',
      },
      'query-input': 'required name=search_term_string',
    },
    publisher: {
      '@type': 'Organization',
      name: 'NexNuggets',
      logo: {
        '@type': 'ImageObject',
        url: 'https://plots.99bookscart.com/images/logo.png',
        width: 200,
        height: 60,
      },
    },
  }
  return (
    <Helmet>
      <meta charset='UTF-8' />
      <title>Learn More Read Less | NexNuggets</title>
      <meta name='title' content='Learn More Read Less | NexNuggets'></meta>

      <meta
        name='description'
        content='Discover concise summaries of Non Fiction Books on NexNuggets'
      ></meta>
      <meta
        name='keyword'
        content='Book Summaries Stories, Poetry, book synopsis, cliffs notes, book review, reading, fairytales'
      ></meta>
      <meta name='twitter:title' content='Book Summaries, Stories & Poetry' />
      <meta
        name='twitter:description'
        content='Discover concise summaries of Non Fiction Books on NexNuggets'
      />
      <meta
        name='twitter:image'
        content='https://www.99bookscart.com/images/logo.png'
      />
      <meta name='twitter:card' content='summary_large_image'></meta>
      <meta property='og:title' content='Book Summaries Stories, Poetry' />
      <head prefix='book: https://ogp.me/ns/book#'></head>
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://www.99bookscart.com' />
      <meta
        property='og:image'
        content='https://99bookscart.com/images/logo.png'
      />
      <meta
        property='og:description'
        content='Discover concise summaries of Non Fiction Books on NexNuggets'
      />
      <meta property='og:site_name' content='NexNuggets' />

      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0'
      ></meta>
      <link rel='canonical' href='https://plots.99bookscart.com'></link>
      <meta name='robots' content='index, follow' />
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(articleStructuredData)}
      </script>
    </Helmet>
  )
}

function convertToSlug(string) {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default HomeMeta
